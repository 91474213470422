import React, { Component } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'chart.js/auto';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { UserStorage } from './Contexts/UserContext';
import isMobile from './Components/Helper/Mobile';
import { NumberStorage } from './Contexts/NumberContext';
import CadastroRapido from './Components/ControleFinanceiro/CadastroRapido';

const NotFoundPage = React.lazy(() => import('./Pages/NotFoundPage'));
const Adm = React.lazy(() => import('./Pages/Adm'));
const Wallet = React.lazy(() => import('./Components/Carteira/Wallet'));
const ControleFinanceiro = React.lazy(() =>
  import('./Pages/ControleFinanceiro/ControleFinanceiro'),
);
const QuantoRendeMil = React.lazy(() =>
  import('./Pages/RendaFixa/QualRendeMil'),
);
const QuantoRendeMilPouso = React.lazy(() =>
  import('./Pages/RendaFixa/QuantoRendeMilPouso'),
);
const QuantoRendeMilhoesPouso = React.lazy(() =>
  import('./Pages/RendaFixa/QuantoRendeMilhoesPouso'),
);
const Categoria = React.lazy(() =>
  import('./Pages/ControleFinanceiro/Categoria'),
);
const Contas = React.lazy(() => import('./Pages/ControleFinanceiro/Contas'));
const Cartoes = React.lazy(() => import('./Pages/ControleFinanceiro/Cartao'));
const Dividas = React.lazy(() => import('./Pages/ControleFinanceiro/Dividas'));
const Patrimonio = React.lazy(() =>
  import('./Pages/ControleFinanceiro/Patrimonio'),
);
const LpPagBank = React.lazy(() => import('./Pages/LP/LpPagBank'));
const DashAnual = React.lazy(() =>
  import('./Pages/ControleFinanceiro/DashAnual'),
);
const Movimentos = React.lazy(() =>
  import('./Pages/ControleFinanceiro/Movimentos'),
);
const Faturas = React.lazy(() => import('./Pages/ControleFinanceiro/Faturas'));
const Inicio = React.lazy(() => import('./Pages/ControleFinanceiro/Inicio'));
const Orcamento = React.lazy(() =>
  import('./Pages/ControleFinanceiro/Orcamento'),
);
const MovimentosCompra = React.lazy(() =>
  import('./Pages/ControleFinanceiro/MovimentosCompra'),
);
const LpControleFinanceiro = React.lazy(() =>
  import('./Pages/LP/LPControleFinanceiro'),
);
const MovimentosAnual = React.lazy(() =>
  import('./Pages/ControleFinanceiro/MovimentosAnual'),
);
const DicaFinanceira = React.lazy(() => import('./Pages/Dica/DicaFinanceira'));
const DicaFinanceiraParam = React.lazy(() =>
  import('./Pages/Dica/DicaFinanceiraParam'),
);
const LPControleFinanceiroObrigado = React.lazy(() =>
  import('./Pages/LP/LPControleFinanceiroObrigado'),
);
const AutenticacaoPro = React.lazy(() =>
  import('./Components/Autenticacao/AutenticacaoPro'),
);
const Metas = React.lazy(() => import('./Pages/ControleFinanceiro/Metas'));
const CarteiraRV = React.lazy(() =>
  import('./Pages/ControleFinanceiro/CarteiraRV'),
);
const Analise = React.lazy(() => import('./Pages/RendaVariavel/Analise.js'));
const LPCalculadoraLiberdadeFin = React.lazy(() =>
  import('./Pages/LP/LPCalculadoraLiberdadeFin.js'),
);
const LPLinkTree = React.lazy(() => import('./Pages/LP/LPLinkTree.js'));
const PreserveUTMs = React.lazy(() =>
  import('./Components/Helper/PreserveUTMs.js'),
);
const LpPlanner360 = React.lazy(() => import('./Pages/LP/LPPlanner360'));
const LPPlanner360Obrigado = React.lazy(() =>
  import('./Pages/LP/LPPlanner360Obrigado.js'),
);
const LPInvestBolsa360 = React.lazy(() =>
  import('./Pages/LP/LPInvestBolsa360.js'),
);
const LPInvestBolsa360Obrigado = React.lazy(() =>
  import('./Pages/LP/LPInvestBolsa360Obrigado.js'),
);
const InicioTreinamento = React.lazy(() =>
  import('./Pages/Planner360/InicioTreinamento'),
);
const PlannerAula1 = React.lazy(() =>
  import('./Pages/Planner360/PlannerAula1.js'),
);
const PlannerContas = React.lazy(() =>
  import('./Pages/Planner360/PlannerContas.js'),
);
const PlannerCategorias = React.lazy(() =>
  import('./Pages/Planner360/PlannerCategorias.js'),
);
const PlannerOrcamento = React.lazy(() =>
  import('./Pages/Planner360/PlannerOrcamento.js'),
);
const PlannerMovimentos = React.lazy(() =>
  import('./Pages/Planner360/PlannerMovimentos.js'),
);
const PlannerExtrato = React.lazy(() =>
  import('./Pages/Planner360/PlannerExtrato.js'),
);
const PlannerDashMensal = React.lazy(() =>
  import('./Pages/Planner360/PlannerDashMensal.js'),
);
const PlannerDashAnual = React.lazy(() =>
  import('./Pages/Planner360/PlannerDashAnual.js'),
);
const PlannerMetas = React.lazy(() =>
  import('./Pages/Planner360/PlannerMetas.js'),
);
const PlannerDividas = React.lazy(() =>
  import('./Pages/Planner360/PlannerDividas.js'),
);
const PlannerFinal = React.lazy(() =>
  import('./Pages/Planner360/PlannerFinal.js'),
);

const HeaderTotal = React.lazy(() => import('./Header/HeaderTotal'));
const Footer = React.lazy(() => import('./Footer/Footer'));
const TesteApi = React.lazy(() => import('./Pages/Adm/TesteApi'));

const Usuarios = React.lazy(() => import('./Pages/Adm/Usuarios'));
const Rotinas = React.lazy(() => import('./Pages/Adm/Rotinas'));
const TwitterEmbebed = React.lazy(() => import('./Pages/TwitterEmbebed.js'));

// politica
const Sobre = React.lazy(() => import('./Pages/SobreNos/Sobre'));
const PoliticaPrivacidade = React.lazy(() =>
  import('./Pages/SobreNos/PoliticaPrivacidade'),
);
const TermosUse = React.lazy(() => import('./Pages/SobreNos/TermosUse'));

// login
const Registro = React.lazy(() => import('./Components/Autenticacao/Registro'));
const Login = React.lazy(() => import('./Components/Autenticacao/Login'));
const Autenticacao = React.lazy(() =>
  import('./Components/Autenticacao/Autenticacao'),
);

//onde investir
const OndeInvestir = React.lazy(() =>
  import('./Pages/OndeInvestir/OndeInvestir'),
);
const OndeInvestirMil = React.lazy(() =>
  import('./Pages/OndeInvestir/OndeInvestirMil'),
);

//onde investir
const Livros = React.lazy(() => import('./Pages/Livros/Livros'));

//fechamento
const Fechamento = React.lazy(() =>
  import('./Components/Bolsa/Fechamento/FechamentoMercado'),
);

const FechamentoProduto = React.lazy(() =>
  import('./Components/Bolsa/Fechamento/FechamentoProduto.js'),
);

//auxilaires
const LGPD = React.lazy(() => import('./Components/Helper/LGPD'));
const Topo = React.lazy(() => import('./Components/Helper/Topo'));

//home
const Home = React.lazy(() => import('./Pages/Home/Home2'));
const Destaques = React.lazy(() => import('./Pages/Destaques/Destaques'));

const Favoritos = React.lazy(() => import('./Components/Carteira/Favoritos'));

//maquininhas
const Maquina = React.lazy(() => import('./Pages/Maquina/Maquina'));
const MaquinaParametro = React.lazy(() =>
  import('./Pages/Maquina/MaquinaParametro'),
);
const MaquinaCartaoParametro = React.lazy(() =>
  import('./Pages/Maquina/MaquinaCartaoParametro'),
);

const Afiliados = React.lazy(() => import('./Pages/Afiliado/Afilaiados'));

const AfilaidosRedirect = React.lazy(() =>
  import('./Pages/Afiliado/AfilaidosRedirect'),
);

const AdministrarSalario = React.lazy(() =>
  import('./Pages/Salario/AdministrarSalario'),
);

const CalculadoraIrSalario = React.lazy(() =>
  import('./Pages/Salario/CalculadoraIrSalario'),
);

// const SalarioLiquido = React.lazy(() =>
//   import('./Pages/Salario/SalarioLiquido'),
// );

// renda fixa
const MelhorRendaFixa = React.lazy(() =>
  import('./Components/RendaFixa/MelhorRendaFixa'),
);

const CalculadoreRendaFixa = React.lazy(() =>
  import('./Pages/RendaFixa/CalculadoraRendaFixa'),
);

const QuantoRende = React.lazy(() => import('./Pages/RendaFixa/QuantoRende'));
const QuantoRendeResumido = React.lazy(() =>
  import('./Pages/RendaFixa/QuantoRendeResumido'),
);
const QuantoRendePeriodo = React.lazy(() =>
  import('./Pages/RendaFixa/QuantoRendePeriodo'),
);

const SimuladorCDI = React.lazy(() => import('./Pages/RendaFixa/SimuladorCDI'));
const QuantoRendePercCDI = React.lazy(() =>
  import('./Pages/RendaFixa/QuantoRendePercCDI'),
);

//calculadora_milhao
const ComoJuntar = React.lazy(() => import('./Pages/ComoJuntar/ComoJuntar'));
const MetaFinanceira = React.lazy(() =>
  import('./Pages/ComoJuntar/MetaFinanceira'),
);

const LiberdadeFinanceira = React.lazy(() =>
  import('./Pages/LiberdadeFinanceira/CalculadoraLiberdadeFinanceira'),
);

const Combustivel = React.lazy(() => import('./Pages/Combustivel/Combustivel'));

const PrecoMedio = React.lazy(() => import('./Pages/RendaVariavel/PrecoMedio'));

//fundos

const Fundos = React.lazy(() => import('./Pages/Fundos/Fundos'));
const FunodsCompleto = React.lazy(() =>
  import('./Pages/Fundos/FunodsCompleto'),
);
const FundosJson = React.lazy(() => import('./Pages/Fundos/FundosJson'));
const Fundo = React.lazy(() => import('./Pages/Fundos/Fundo'));
const FundoParametro = React.lazy(() =>
  import('./Pages/Fundos/FundoParametro'),
);
const NovosFundos = React.lazy(() => import('./Components/Fundos/Lancamentos'));
const ComparadorFundos = React.lazy(() =>
  import('./Pages/Comparador/ComparadorFundos'),
);

const FII = React.lazy(() => import('./Pages/Melhores/FundosImobiliarios'));
const FIICompleto = React.lazy(() =>
  import('./Pages/Melhores/FundosImobiliariosCompleto'),
);
const Stocks = React.lazy(() => import('./Pages/Melhores/Stocks'));
const Acoes = React.lazy(() => import('./Pages/Melhores/Acoes'));
const AcoesCompleto = React.lazy(() =>
  import('./Pages/Melhores/AcoesCompleto'),
);

const Cripto = React.lazy(() => import('./Pages/Melhores/Cripto'));
const CriptoCompleto = React.lazy(() =>
  import('./Pages/Melhores/CriptoCompleto'),
);

const Etf = React.lazy(() => import('./Pages/Melhores/Etf'));
const EtfCompleto = React.lazy(() => import('./Pages/Melhores/EtfCompleto'));
const EtfBdr = React.lazy(() => import('./Pages/Melhores/EtfBdr'));
const EtfBdrCompleto = React.lazy(() =>
  import('./Pages/Melhores/EtfBdrCompleto'),
);
const Bdr = React.lazy(() => import('./Pages/Melhores/Bdr'));
const BdrCompleto = React.lazy(() => import('./Pages/Melhores/BdrCompleto'));

const RendaVariavel = React.lazy(() =>
  import('./Pages/RendaVariavel/RendaVariavel'),
);

const PageSimuladorDividendos = React.lazy(() =>
  import('./Pages/Simulador/PageSimuladorDividendos'),
);
const Dividendos = React.lazy(() => import('./Pages/RendaVariavel/Dividendos'));
const Fundamentos = React.lazy(() =>
  import('./Pages/RendaVariavel/Fundamentos'),
);
const Intrinseco = React.lazy(() => import('./Pages/RendaVariavel/Intrinseco'));

const QuantoRendeFII = React.lazy(() =>
  import('./Pages/RendaVariavel/QuantoRendeFII'),
);

const QuantoRendeCripto = React.lazy(() =>
  import('./Pages/RendaVariavel/QuantoRendeCripto'),
);

const QuantoRendeMilCripto = React.lazy(() =>
  import('./Pages/RendaVariavel/QuantoRendeMilCripto'),
);

const ComparacaoDoDia = React.lazy(() =>
  import('./Pages/Pouso/ComparacaoDoDia.js'),
);
const Comparador = React.lazy(() => import('./Pages/Comparador/Comparador'));

const Emprestimo = React.lazy(() => import('./Pages/Emprestimo/Emprestimo'));
const CalculadoraEmprestimo = React.lazy(() =>
  import('./Pages/Emprestimo/CalculadoraEmprestimo'),
);
const Blog = React.lazy(() => import('./Pages/Blog/Blog'));
const FeedBlog = React.lazy(() => import('./Pages/Blog/FeedBlog'));
const BlogRedirect = React.lazy(() => import('./Pages/Blog/BlogRedirect'));
const EmpresasEmprestimo = React.lazy(() =>
  import('./Pages/Emprestimo/EmpresasEmprestimo'),
);
const Cartao = React.lazy(() => import('./Pages/Cartao/Cartao'));
const CartaoRenda = React.lazy(() => import('./Pages/Cartao/CartaoRenda'));
const CartaoLimite = React.lazy(() => import('./Pages/Cartao/CartaoLimite'));

const SimuladorBolsaValores = React.lazy(() =>
  import('./Pages/Simulador/SimuladorBolsaValores'),
);
const SimuladorFundos = React.lazy(() =>
  import('./Pages/Simulador/SimuladorFundos'),
);

const SimuladorFundosClasse = React.lazy(() =>
  import('./Pages/Simulador/SimuladorFundosClasse'),
);

const SimuladorRendaVariavel = React.lazy(() =>
  import('./Pages/Simulador/SimuladorRendaVariavel'),
);

const TesteRewardsAds = React.lazy(() =>
  import('./Pages/Teste/TesteRewardsAds'),
);

const PopUpCapturaLead = React.lazy(() =>
  import('./Components/PopUps/PopUpCapturaLead.js'),
);

const PopUpGuardarDinheiroPro = React.lazy(() =>
  import('./Components/PopUps/PopUpGuardarDinheiroPro.js'),
);

const SairDividas = React.lazy(() => import('./Pages/Dividas/SairDividas.js'));

const Backlinks = React.lazy(() => import('./Pages/BackLinks/BackLinks'));

const PlanoFinanceiro = React.lazy(() =>
  import('./Pages/PlanoFinanceiro/PlanoFinanceiro.js'),
);

const PlanoFinanceiroDividas1 = React.lazy(() =>
  import('./Pages/PlanoFinanceiro/PlanoFinanceiroDividas1.js'),
);

const PlanoFinanceiroDividas2 = React.lazy(() =>
  import('./Pages/PlanoFinanceiro/PlanoFinanceiroDividas2.js'),
);

const PlanoFinanceiroDividas3 = React.lazy(() =>
  import('./Pages/PlanoFinanceiro/PlanoFinanceiroDividas3.js'),
);

const PlanoFinanceiroDividas4 = React.lazy(() =>
  import('./Pages/PlanoFinanceiro/PlanoFinanceiroDividas4.js'),
);

const PlanoFinanceiroDividas5 = React.lazy(() =>
  import('./Pages/PlanoFinanceiro/PlanoFinanceiroDividas5.js'),
);

const PlanoFinanceiroDividas6 = React.lazy(() =>
  import('./Pages/PlanoFinanceiro/PlanoFinanceiroDividas6.js'),
);

const PlanoFinanceiroDividas7 = React.lazy(() =>
  import('./Pages/PlanoFinanceiro/PlanoFinanceiroDividas7.js'),
);

const PlanoFinanceiroEmpato1 = React.lazy(() =>
  import('./Pages/PlanoFinanceiro/PlanoFinanceiroEmpato1.js'),
);

const PlanoFinanceiroEmpato2 = React.lazy(() =>
  import('./Pages/PlanoFinanceiro/PlanoFinanceiroEmpato2.js'),
);

const PlanoFinanceiroEmpato3 = React.lazy(() =>
  import('./Pages/PlanoFinanceiro/PlanoFinanceiroEmpato3.js'),
);

const PlanoFinanceiroEmpato4 = React.lazy(() =>
  import('./Pages/PlanoFinanceiro/PlanoFinanceiroEmpato4.js'),
);

const PlanoFinanceiroEmpato5 = React.lazy(() =>
  import('./Pages/PlanoFinanceiro/PlanoFinanceiroEmpato5.js'),
);

const PlanoFinanceiroSaldo1 = React.lazy(() =>
  import('./Pages/PlanoFinanceiro/PlanoFinanceiroSaldo1.js'),
);

const PlanoFinanceiroSaldo2 = React.lazy(() =>
  import('./Pages/PlanoFinanceiro/PlanoFinanceiroSaldo2.js'),
);

const PlanoFinanceiroSaldo3 = React.lazy(() =>
  import('./Pages/PlanoFinanceiro/PlanoFinanceiroSaldo3.js'),
);

const PlanoFinanceiroSaldo4 = React.lazy(() =>
  import('./Pages/PlanoFinanceiro/PlanoFinanceiroSaldo4.js'),
);

const PlanoFinanceiroSaldo5 = React.lazy(() =>
  import('./Pages/PlanoFinanceiro/PlanoFinanceiroSaldo5.js'),
);

const PlanoFinanceiroSaldo6 = React.lazy(() =>
  import('./Pages/PlanoFinanceiro/PlanoFinanceiroSaldo6.js'),
);

const PlanoFinanceiroSaldo7 = React.lazy(() =>
  import('./Pages/PlanoFinanceiro/PlanoFinanceiroSaldo7.js'),
);

class App extends Component {
  state = {
    mobile: false,
    isLandingPage: false,
    isControleFinanceiro: false,
  };

  componentDidMount() {
    const mobile = isMobile();
    this.setState({ mobile: mobile });
    if (window.location.href.includes('/lp/')) {
      this.setState({ isLandingPage: true });
    }

    if (window.location.href.includes('plano-pro')) {
      this.setState({ isControleFinanceiro: true });
    }
  }

  render() {
    return (
      <>
        <BrowserRouter>
          <PreserveUTMs>
            <UserStorage>
              {!this.state.isLandingPage && !this.state.isControleFinanceiro ? (
                <React.Suspense fallback={<div>Carregando...</div>}>
                  {/* {!this.state.mobile ? <HeaderTotal /> : <HeaderTotal />} */}
                  <HeaderTotal />
                </React.Suspense>
              ) : null}

              {!this.state.isLandingPage ? (
                <React.Suspense fallback={<div></div>}>
                  <LGPD />
                </React.Suspense>
              ) : null}

              {this.state.isControleFinanceiro ? (
                <React.Suspense fallback={<div>Carregando...</div>}>
                  <HeaderTotal />
                </React.Suspense>
              ) : null}

              <Routes>
                <Route
                  path="/planner/treinamento"
                  element={
                    <React.Suspense fallback={<div>Carregando...</div>}>
                      <InicioTreinamento />
                    </React.Suspense>
                  }
                />
                <Route
                  path="/planner/aula1"
                  element={
                    <React.Suspense fallback={<div>Carregando...</div>}>
                      <PlannerAula1 />
                    </React.Suspense>
                  }
                />
                <Route
                  path="/planner/aula-cadastro-contas"
                  element={
                    <React.Suspense fallback={<div>Carregando...</div>}>
                      <PlannerContas />
                    </React.Suspense>
                  }
                />
                <Route
                  path="/planner/aula-cadastro-categorias"
                  element={
                    <React.Suspense fallback={<div>Carregando...</div>}>
                      <PlannerCategorias />
                    </React.Suspense>
                  }
                />

                <Route
                  path="/planner/aula-orcamento"
                  element={
                    <React.Suspense fallback={<div>Carregando...</div>}>
                      <PlannerOrcamento />
                    </React.Suspense>
                  }
                />

                <Route
                  path="/planner/aula-movimentos"
                  element={
                    <React.Suspense fallback={<div>Carregando...</div>}>
                      <PlannerMovimentos />
                    </React.Suspense>
                  }
                />

                <Route
                  path="/planner/aula-extrato"
                  element={
                    <React.Suspense fallback={<div>Carregando...</div>}>
                      <PlannerExtrato />
                    </React.Suspense>
                  }
                />

                <Route
                  path="/planner/aula-dash-mensal"
                  element={
                    <React.Suspense fallback={<div>Carregando...</div>}>
                      <PlannerDashMensal />
                    </React.Suspense>
                  }
                />

                <Route
                  path="/planner/aula-dash-anual"
                  element={
                    <React.Suspense fallback={<div>Carregando...</div>}>
                      <PlannerDashAnual />
                    </React.Suspense>
                  }
                />

                <Route
                  path="/planner/aula-metas-investimentos"
                  element={
                    <React.Suspense fallback={<div>Carregando...</div>}>
                      <PlannerMetas />
                    </React.Suspense>
                  }
                />

                <Route
                  path="/planner/aula-dividas"
                  element={
                    <React.Suspense fallback={<div>Carregando...</div>}>
                      <PlannerDividas />
                    </React.Suspense>
                  }
                />

                <Route
                  path="/planner/aula-consideracoes-finais"
                  element={
                    <React.Suspense fallback={<div>Carregando...</div>}>
                      <PlannerFinal />
                    </React.Suspense>
                  }
                />

                <Route
                  path="/lp/promocao-de-maquininhas"
                  element={
                    <React.Suspense fallback={<div>Carregando...</div>}>
                      <LpPagBank />
                    </React.Suspense>
                  }
                />
                <Route
                  path="/lp/guardar-dinheiro-planner-360"
                  element={
                    <React.Suspense fallback={<div>Carregando...</div>}>
                      <LpPlanner360 />
                    </React.Suspense>
                  }
                />
                <Route
                  path="/lp/guardar-dinheiro-investbolsa-360"
                  element={
                    <React.Suspense fallback={<div>Carregando...</div>}>
                      <LPInvestBolsa360 />
                    </React.Suspense>
                  }
                />
                <Route
                  path="/lp/guardar-dinheiro-investbolsa-360/obrigado"
                  element={
                    <React.Suspense fallback={<div>Carregando...</div>}>
                      <LPInvestBolsa360Obrigado />
                    </React.Suspense>
                  }
                />
                <Route
                  path="/lp/guardar-dinheiro-pro"
                  element={
                    <React.Suspense fallback={<div>Carregando...</div>}>
                      <LpControleFinanceiro />
                    </React.Suspense>
                  }
                />

                <Route
                  path="/lp/promocao-de-maquininhas"
                  element={
                    <React.Suspense fallback={<div>Carregando...</div>}>
                      <LpPagBank />
                    </React.Suspense>
                  }
                />

                <Route
                  path="/lp/guardardinheiro-linktree"
                  element={
                    <React.Suspense fallback={<div>Carregando...</div>}>
                      <LPLinkTree />
                    </React.Suspense>
                  }
                />
                <Route
                  path="/lp/calculadora-liberdade-financeira"
                  element={
                    <React.Suspense fallback={<div>Carregando...</div>}>
                      <LPCalculadoraLiberdadeFin />
                    </React.Suspense>
                  }
                />
                <Route
                  path="/lp/guardar-dinheiro-pro/obrigado"
                  element={
                    <React.Suspense fallback={<div>Carregando...</div>}>
                      <LPControleFinanceiroObrigado />
                    </React.Suspense>
                  }
                />

                <Route
                  path="/lp/guardar-dinheiro-planner-360/obrigado"
                  element={
                    <React.Suspense fallback={<div>Carregando...</div>}>
                      <LPPlanner360Obrigado />
                    </React.Suspense>
                  }
                />
                <Route
                  path="/users"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <Usuarios />
                    </React.Suspense>
                  }
                />
                <Route
                  path="/rotinas"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <Rotinas />
                    </React.Suspense>
                  }
                />
                <Route
                  path="/testeapi"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <TesteApi />
                    </React.Suspense>
                  }
                />
                <Route
                  path="/livros"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <Livros />
                    </React.Suspense>
                  }
                />

                <Route
                  path="/plano-financeiro"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <PlanoFinanceiro />
                    </React.Suspense>
                  }
                />

                <Route
                  path="/plano-financeiro/jornada-1/passo-1"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <PlanoFinanceiroDividas1 />
                    </React.Suspense>
                  }
                />
                <Route
                  path="/plano-financeiro/jornada-1/passo-2"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <PlanoFinanceiroDividas2 />
                    </React.Suspense>
                  }
                />

                <Route
                  path="/plano-financeiro/jornada-1/passo-3"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <PlanoFinanceiroDividas3 />
                    </React.Suspense>
                  }
                />

                <Route
                  path="/plano-financeiro/jornada-1/passo-4"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <PlanoFinanceiroDividas4 />
                    </React.Suspense>
                  }
                />

                <Route
                  path="/plano-financeiro/jornada-1/passo-5"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <PlanoFinanceiroDividas5 />
                    </React.Suspense>
                  }
                />

                <Route
                  path="/plano-financeiro/jornada-1/passo-6"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <PlanoFinanceiroDividas6 />
                    </React.Suspense>
                  }
                />

                <Route
                  path="/plano-financeiro/jornada-1/passo-7"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <PlanoFinanceiroDividas7 />
                    </React.Suspense>
                  }
                />

                <Route
                  path="/plano-financeiro/jornada-2/passo-1"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <PlanoFinanceiroEmpato1 />
                    </React.Suspense>
                  }
                />

                <Route
                  path="/plano-financeiro/jornada-2/passo-2"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <PlanoFinanceiroEmpato2 />
                    </React.Suspense>
                  }
                />

                <Route
                  path="/plano-financeiro/jornada-2/passo-3"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <PlanoFinanceiroEmpato3 />
                    </React.Suspense>
                  }
                />

                <Route
                  path="/plano-financeiro/jornada-2/passo-4"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <PlanoFinanceiroEmpato4 />
                    </React.Suspense>
                  }
                />

                <Route
                  path="/plano-financeiro/jornada-2/passo-5"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <PlanoFinanceiroEmpato5 />
                    </React.Suspense>
                  }
                />

                <Route
                  path="/plano-financeiro/jornada-3/passo-1"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <PlanoFinanceiroSaldo1 />
                    </React.Suspense>
                  }
                />

                <Route
                  path="/plano-financeiro/jornada-3/passo-2"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <PlanoFinanceiroSaldo2 />
                    </React.Suspense>
                  }
                />

                <Route
                  path="/plano-financeiro/jornada-3/passo-3"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <PlanoFinanceiroSaldo3 />
                    </React.Suspense>
                  }
                />

                <Route
                  path="/plano-financeiro/jornada-3/passo-4"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <PlanoFinanceiroSaldo4 />
                    </React.Suspense>
                  }
                />

                <Route
                  path="/plano-financeiro/jornada-3/passo-5"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <PlanoFinanceiroSaldo5 />
                    </React.Suspense>
                  }
                />

                <Route
                  path="/plano-financeiro/jornada-3/passo-6"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <PlanoFinanceiroSaldo6 />
                    </React.Suspense>
                  }
                />

                <Route
                  path="/plano-financeiro/jornada-3/passo-7"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <PlanoFinanceiroSaldo7 />
                    </React.Suspense>
                  }
                />

                <Route
                  path="/dica-financeira-do-dia"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <DicaFinanceira />
                    </React.Suspense>
                  }
                />
                <Route
                  path="/dica-financeira-do-dia/dia/:data_dica"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <DicaFinanceiraParam />
                    </React.Suspense>
                  }
                />
                <Route
                  path="analise-do-dia/:tipo"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <ComparacaoDoDia />
                    </React.Suspense>
                  }
                />
                <Route
                  path="/tweets"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <TwitterEmbebed />
                    </React.Suspense>
                  }
                />

                <Route
                  path="/produtos/:produto"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <Afiliados />
                    </React.Suspense>
                  }
                />

                <Route
                  path="/produtos/carrinho"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <AfilaidosRedirect />
                    </React.Suspense>
                  }
                />

                <Route
                  path="/melhor-maquininha-de-cartao"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <Maquina />
                    </React.Suspense>
                  }
                />
                <Route
                  path="/melhor-maquininha-de-cartao/:parametro"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <MaquinaCartaoParametro />
                    </React.Suspense>
                  }
                />
                <Route
                  path="/melhor-maquininha/:parametro"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <MaquinaParametro />
                    </React.Suspense>
                  }
                />

                <Route
                  path="/emprestimo/:valor/simulador"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <Emprestimo />
                    </React.Suspense>
                  }
                />
                <Route
                  path="/emprestimo/:valor/simulador-online"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <CalculadoraEmprestimo />
                    </React.Suspense>
                  }
                />

                <Route
                  path="/registro"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <Registro />
                    </React.Suspense>
                  }
                />
                <Route
                  path="/login"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <Login />
                    </React.Suspense>
                  }
                />
                <Route
                  path="/autenticacao/*"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <Autenticacao />
                    </React.Suspense>
                  }
                />

                <Route
                  path="/autenticacao-pro/*"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <AutenticacaoPro />
                    </React.Suspense>
                  }
                />

                <Route
                  path="/"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <Home />
                    </React.Suspense>
                  }
                />
                <Route
                  path="/melhores-do-mes"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <Destaques />
                    </React.Suspense>
                  }
                />
                <Route
                  path="/home"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <Home />{' '}
                    </React.Suspense>
                  }
                />

                <Route
                  path="/onde-investir/:valor"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <OndeInvestir />
                    </React.Suspense>
                  }
                />
                <Route
                  path="/onde-investir/:valor/mil"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <OndeInvestirMil />
                    </React.Suspense>
                  }
                />
                <Route
                  path="/fechamento-mercado"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <Fechamento />
                    </React.Suspense>
                  }
                />

                <Route
                  path="/fechamento-mercado/:tipoInvestimento"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <FechamentoProduto />
                    </React.Suspense>
                  }
                />

                <Route
                  path="/fundos-de-investimento"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <Fundos />
                    </React.Suspense>
                  }
                />

                <Route
                  path="/teste"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <FundosJson />
                    </React.Suspense>
                  }
                />

                <Route
                  path="/teste-reward"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <TesteRewardsAds />
                    </React.Suspense>
                  }
                />
                <Route
                  path="/melhores-fundos-de-investimento"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <FunodsCompleto />
                    </React.Suspense>
                  }
                />

                <Route
                  path="/fundo/:cnpj/:nome"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <Fundo />
                    </React.Suspense>
                  }
                />
                <Route
                  path="/fundo/:cnpj/:tipo/:nome/"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <FundoParametro />
                    </React.Suspense>
                  }
                />
                <Route
                  path="/novos-fundos"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <NovosFundos />{' '}
                    </React.Suspense>
                  }
                />

                <Route
                  path="/quanto-rende/:valor/:produto"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <QuantoRende />
                    </React.Suspense>
                  }
                />

                <Route
                  path="/calculadoras/:produto/resumido"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <QuantoRendeResumido />
                    </React.Suspense>
                  }
                />

                <Route
                  path="/quanto-rende/:investimentoMensal/:tempo/anos"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <QuantoRendePeriodo />
                    </React.Suspense>
                  }
                />

                <Route
                  path="/quanto/:valorcdi/cdi"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <SimuladorCDI />
                    </React.Suspense>
                  }
                />

                <Route
                  path="/quanto-rende/:valor/:valorcdi/cdi"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <QuantoRendePercCDI />
                    </React.Suspense>
                  }
                />

                <Route
                  path="/quanto-rende/:valor/mil/:produto/simulador"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <QuantoRendeMil />
                    </React.Suspense>
                  }
                />

                <Route
                  path="/quanto-rende/:valor/mil/:produto"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <QuantoRendeMilPouso />
                    </React.Suspense>
                  }
                />

                <Route
                  path="/quanto-rende/:valor/milhoes/:produto"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <QuantoRendeMilhoesPouso />
                    </React.Suspense>
                  }
                />

                <Route
                  path="/calculadoras/alcool-ou-gasolina"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <Combustivel />
                    </React.Suspense>
                  }
                />

                <Route
                  path="/como-juntar/:valor/"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <ComoJuntar />
                    </React.Suspense>
                  }
                />

                <Route
                  path="/meta-financeira"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <MetaFinanceira />
                    </React.Suspense>
                  }
                />

                <Route
                  path="/calculadoras/preco-medio"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <PrecoMedio />
                    </React.Suspense>
                  }
                />

                <Route
                  path="/calculadoras/liberdade-financeira"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <LiberdadeFinanceira />
                    </React.Suspense>
                  }
                />

                <Route
                  path="/calculadoras/quanto-investir/:tipoRenda/:valorSalario"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <LiberdadeFinanceira />
                    </React.Suspense>
                  }
                />

                <Route
                  path="/renda-fixa/:parametro"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <MelhorRendaFixa />
                    </React.Suspense>
                  }
                />

                <Route
                  path="/como-administrar-salario/:valor/reais"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <AdministrarSalario />
                    </React.Suspense>
                  }
                />

                <Route
                  path="/quem-ganha/:valor/paga-quanto-imposto"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <CalculadoraIrSalario />
                    </React.Suspense>
                  }
                />

                <Route
                  path="/como-organizar-salario/:valor/reais"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <AdministrarSalario />
                    </React.Suspense>
                  }
                />

                <Route
                  path="/calculadora-renda-fixa"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <CalculadoreRendaFixa />
                    </React.Suspense>
                  }
                />

                <Route
                  path="/melhores-stocks"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <Stocks />
                    </React.Suspense>
                  }
                />
                <Route
                  path="/melhores-acoes"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <AcoesCompleto />
                    </React.Suspense>
                  }
                />
                <Route
                  path="/acoes"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <Acoes />
                    </React.Suspense>
                  }
                />
                <Route
                  path="/bdr"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <Bdr />
                    </React.Suspense>
                  }
                />

                <Route
                  path="/melhores-bdr"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <BdrCompleto />
                    </React.Suspense>
                  }
                />
                <Route
                  path="/etf"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <Etf />
                    </React.Suspense>
                  }
                />

                <Route
                  path="/melhores-etf"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <EtfCompleto />
                    </React.Suspense>
                  }
                />

                <Route
                  path="/bdr-etf"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <EtfBdr />
                    </React.Suspense>
                  }
                />

                <Route
                  path="/melhores-bdr-etf"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <EtfBdrCompleto />
                    </React.Suspense>
                  }
                />
                <Route
                  path="/criptomoeda"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <Cripto />
                    </React.Suspense>
                  }
                />

                <Route
                  path="/melhores-criptomoeda"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <CriptoCompleto />
                    </React.Suspense>
                  }
                />
                <Route
                  path="/melhores-fundos-imobiliarios"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <FIICompleto />
                    </React.Suspense>
                  }
                />
                <Route
                  path="/fundos-imobiliarios"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <FII />
                    </React.Suspense>
                  }
                />
                <Route
                  path="/quanto-rende-fundo-imobiliario"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <FII />
                    </React.Suspense>
                  }
                />

                <Route
                  path="/simulador-de-dividendos"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <PageSimuladorDividendos />
                    </React.Suspense>
                  }
                />
                <Route
                  path="/sobre"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <Sobre />{' '}
                    </React.Suspense>
                  }
                />
                <Route
                  path="/politica-privacidade"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <PoliticaPrivacidade />{' '}
                    </React.Suspense>
                  }
                />
                <Route
                  path="/termos-de-uso"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <TermosUse />{' '}
                    </React.Suspense>
                  }
                />
                <Route
                  path="*"
                  element={
                    <React.Suspense fallback={<div>Carregando...</div>}>
                      <NotFoundPage />
                    </React.Suspense>
                  }
                />

                <Route
                  path="/comparador-fundos-imobiliarios"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <Comparador tipoInvestimento="fii" />{' '}
                    </React.Suspense>
                  }
                />

                <Route
                  path="/comparador-fundos-imobiliarios/:ticker"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <Comparador tipoInvestimento="fii" />
                    </React.Suspense>
                  }
                />
                <Route
                  path="/comparador-acoes"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <Comparador tipoInvestimento="acoes" />
                    </React.Suspense>
                  }
                />

                <Route
                  path="/comparador-acoes/:ticker"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <Comparador tipoInvestimento="acoes" />
                    </React.Suspense>
                  }
                />
                <Route
                  path="/comparador-bdr"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <Comparador tipoInvestimento="bdr" />
                    </React.Suspense>
                  }
                />

                <Route
                  path="/comparador-bdr/:ticker"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <Comparador tipoInvestimento="bdr" />
                    </React.Suspense>
                  }
                />
                <Route
                  path="/comparador-etf"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <Comparador tipoInvestimento="etf" />
                    </React.Suspense>
                  }
                />

                <Route
                  path="/comparador-etf/:ticker"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <Comparador tipoInvestimento="etf" />
                    </React.Suspense>
                  }
                />

                <Route
                  path="/comparador-cripto"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <Comparador tipoInvestimento="cripto" />
                    </React.Suspense>
                  }
                />

                <Route
                  path="/comparador-cripto/:ticker"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <Comparador tipoInvestimento="cripto" />
                    </React.Suspense>
                  }
                />
                <Route
                  path="/comparador-fundos-de-investimentos"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <ComparadorFundos />
                    </React.Suspense>
                  }
                />

                <Route
                  path="/dashboard/favoritos"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <Favoritos />
                    </React.Suspense>
                  }
                />

                <Route
                  path="/dividas/como-sair-das-dividas-de/:parametro"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <SairDividas />
                    </React.Suspense>
                  }
                />

                <Route
                  path="/adm"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <Adm />
                    </React.Suspense>
                  }
                />
                <Route
                  path="/educacao-financeira"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <Blog />
                    </React.Suspense>
                  }
                />
                <Route
                  path="/educacao-financeira2"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <FeedBlog />
                    </React.Suspense>
                  }
                />

                <Route
                  path="/blog-posts/:urlBlog"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <BlogRedirect />
                    </React.Suspense>
                  }
                />

                <Route
                  path="/lista-melhores-emprestimos"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <EmpresasEmprestimo />
                    </React.Suspense>
                  }
                />

                <Route
                  path="/melhor-cartao-credito"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <Cartao />
                    </React.Suspense>
                  }
                />
                <Route
                  path="/cartao-credito/:valor/renda-minima"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <CartaoRenda />
                    </React.Suspense>
                  }
                />

                <Route
                  path="/cartao-credito/limite/:valor"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <CartaoLimite />
                    </React.Suspense>
                  }
                />
                <Route
                  path="/quanto-rende/:valor/fundo-imobiliario"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <QuantoRendeFII />
                    </React.Suspense>
                  }
                />

                <Route
                  path="/cripto/quanto-rende/:valor/:cripto"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <QuantoRendeCripto />
                    </React.Suspense>
                  }
                />

                <Route
                  path="/cripto/quanto-rende/:valor/mil/:cripto"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <QuantoRendeMilCripto />
                    </React.Suspense>
                  }
                />
                <Route
                  path="/:tipoInvestimento/:acao"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <RendaVariavel />
                    </React.Suspense>
                  }
                />
                <Route
                  path="/:tipoInvestimento/:acao/simulacao/:urlParamDividendos"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <Dividendos />
                    </React.Suspense>
                  }
                />
                <Route
                  path="/:tipoInvestimento/:acao/fundamentos"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <Fundamentos />
                    </React.Suspense>
                  }
                />

                <Route
                  path="/:tipoInvestimento/:acao/fundamentos/preco-justo"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <Intrinseco />
                    </React.Suspense>
                  }
                />
                <Route
                  path="/:tipoInvestimento/:urlParam/:acao"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <RendaVariavel />
                    </React.Suspense>
                  }
                />
                <Route
                  path="/:tipoInvestimento/:quanto-rende/:valor/:acao"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <RendaVariavel />
                    </React.Suspense>
                  }
                />

                <Route
                  path="/simulador/quanto-rende/:valor/bolsa-de-valores"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <SimuladorBolsaValores />
                    </React.Suspense>
                  }
                />

                <Route
                  path="/simulador/quanto-rende/:valor/:tiporv"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <SimuladorRendaVariavel />
                    </React.Suspense>
                  }
                />

                <Route
                  path="/simulador/quanto-rende/:valor/fundos-de-investimento"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <SimuladorFundos />
                    </React.Suspense>
                  }
                />

                <Route
                  path="/simulador/quanto-rende/:valor/fundos-de-investimento/:classe"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <SimuladorFundosClasse />
                    </React.Suspense>
                  }
                />

                <Route
                  path="/dashboard"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <Wallet />
                    </React.Suspense>
                  }
                />
                <Route
                  path="/dashboard/wallet"
                  element={
                    <React.Suspense>
                      <Wallet />
                    </React.Suspense>
                  }
                />

                <Route
                  path="/bl/post/:url"
                  element={
                    <React.Suspense fallback={<div></div>}>
                      <Backlinks />
                    </React.Suspense>
                  }
                />

                <Route
                  path="/plano-pro/home"
                  element={
                    <NumberStorage>
                      <Inicio />
                    </NumberStorage>
                  }
                />

                <Route
                  path="/plano-pro/resumo-mensal"
                  element={
                    <NumberStorage>
                      <ControleFinanceiro />
                    </NumberStorage>
                  }
                />
                <Route
                  path="/plano-pro/resumo-anual"
                  element={
                    <NumberStorage>
                      <DashAnual />
                    </NumberStorage>
                  }
                />
                <Route
                  path="/plano-pro/categorias"
                  element={
                    <React.Suspense fallback={<div>Carregando...</div>}>
                      <Categoria />
                    </React.Suspense>
                  }
                />
                <Route
                  path="/plano-pro/contas"
                  element={
                    <React.Suspense fallback={<div>Carregando...</div>}>
                      <Contas />
                    </React.Suspense>
                  }
                />
                <Route
                  path="/plano-pro/cartao"
                  element={
                    <React.Suspense fallback={<div>Carregando...</div>}>
                      <Cartoes />
                    </React.Suspense>
                  }
                />

                <Route
                  path="/plano-pro/dividas"
                  element={
                    <React.Suspense fallback={<div>Carregando...</div>}>
                      <NumberStorage>
                        <Dividas />
                      </NumberStorage>
                    </React.Suspense>
                  }
                />

                <Route
                  path="/plano-pro/patrimonio"
                  element={
                    <React.Suspense fallback={<div>Carregando...</div>}>
                      <NumberStorage>
                        <Patrimonio />
                      </NumberStorage>
                    </React.Suspense>
                  }
                />

                <Route
                  path="/plano-pro/metas"
                  element={
                    <React.Suspense fallback={<div>Carregando...</div>}>
                      <NumberStorage>
                        <Metas />
                      </NumberStorage>
                    </React.Suspense>
                  }
                />

                <Route
                  path="/plano-pro/orcamento"
                  element={
                    <React.Suspense fallback={<div>Carregando...</div>}>
                      <Orcamento />
                    </React.Suspense>
                  }
                />
                <Route
                  path="/plano-pro/movimentos"
                  element={
                    <React.Suspense fallback={<div>Carregando...</div>}>
                      <NumberStorage>
                        <Movimentos />
                      </NumberStorage>
                    </React.Suspense>
                  }
                />
                <Route
                  path="/plano-pro/movimentos-ano"
                  element={
                    <React.Suspense fallback={<div>Carregando...</div>}>
                      <NumberStorage>
                        <MovimentosAnual />
                      </NumberStorage>
                    </React.Suspense>
                  }
                />
                <Route
                  path="/plano-pro/movimentos-mes"
                  element={
                    <React.Suspense fallback={<div>Carregando...</div>}>
                      <NumberStorage>
                        <MovimentosCompra />
                      </NumberStorage>
                    </React.Suspense>
                  }
                />
                <Route
                  path="/plano-pro/faturas"
                  element={
                    <React.Suspense fallback={<div>Carregando...</div>}>
                      <NumberStorage>
                        <Faturas />
                      </NumberStorage>
                    </React.Suspense>
                  }
                />

                <Route
                  path="/plano-pro/ativos-rv"
                  element={
                    <React.Suspense fallback={<div>Carregando...</div>}>
                      <NumberStorage>
                        <CarteiraRV />
                      </NumberStorage>
                    </React.Suspense>
                  }
                />

                <Route
                  path="/analise-inteligente/"
                  element={
                    <React.Suspense fallback={<div>Carregando...</div>}>
                      <Analise />
                    </React.Suspense>
                  }
                />
              </Routes>

              {/* {this.state.isLandingPage ? null : <Footer isLP={false} />} */}
              <React.Suspense fallback={<div></div>}>
                {this.state.isLandingPage ? null : <Footer isLP={false} />}
              </React.Suspense>
            </UserStorage>
          </PreserveUTMs>
        </BrowserRouter>

        <React.Suspense fallback={<div></div>}>
          <Topo />
        </React.Suspense>

        {this.state.isControleFinanceiro ? <CadastroRapido /> : null}
      </>
    );
  }
}

export default App;
